import React from "react";
import Slider from "react-slick";
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import  "../components/scss/_carousel.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SeemlessIntegrationSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slides: this.props.sliderItems,
        };
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    prev() {
        this.slider.slickPrev();
    }
  render() {
    var settings = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            dots: true
          }
        }
      ]
    };
    return (
    <div>
        <div className="slider-before">
            <div className="carousel--nav" style={{ textAlign: "center" }}>
                <button className="button-nostyle" onClick={this.prev}>
                <FontAwesomeIcon icon="angle-left" size="3x" />
                </button>
                <button className="button-nostyle" onClick={this.next}>
                <FontAwesomeIcon icon="angle-right" size="3x" />
                </button>
            </div>
        </div>
      <Slider ref={slider => (this.slider = slider)} {...settings}>
        {this.state.slides.map(function(slide) {
            return (
                <div className="columns slide-wrapper" key={slide.key}>
                    <div className="column is-12">
                        <PreviewCompatibleImage imageInfo={slide.image} />
                    </div>
                </div>
            );
          })}
      </Slider>
    </div>
    );
  }
}

export default SeemlessIntegrationSlider
