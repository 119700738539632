import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import TwoColumn from '../components/TwoColumn'
import SubNavbar from '../components/SubNavbar'
import ButtonToggle from '../components/ButtonToggle'
import ClientLogos from '../components/ClientLogos'
import SeemlessIntegrationSlider from '../components/SeemlessIntegrationSlider'
import PreviewCompatibleBgImage from '../components/PreviewCompatibleBgImage'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import VideoModal from '../components/VideoModal'

/* eslint-disable */

export const PersonalizePageTemplate = ({
  title,
  metatitle,
  metadescription,
  bgimageprimary,
  bgimagesecondary,
  heading,
  subheading,
  ctatitle,
  ctaurl,
  ctasecondarytitle,
  ctasecondaryurl,
  image,
  herovideo,
  allensinterests,
  customerinterest,
  seemlessintegration,
  toggleimage,
  reasons,
  personalizeemails,
  clientlogos,
  finalquote,
  helmet,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return <>
    <div className="personalize-overview-pg">
      {helmet || ''}
      <div
        className="full-width-image margin-top-0 hero-bg"
        style={{
          backgroundImage: `url(${
            !!bgimageprimary.childImageSharp ? bgimageprimary.childImageSharp.fluid.src : bgimageprimary
          })`,
          backgroundPosition: `center center`,
          justifyContent: 'left',
          flexWrap: `wrap`,
          backgroundRepeat: 'no-repeat',
          height: 'auto',
          paddingBottom: '60px'
        }}
      >
        <div className="container">

          <div className="hero-text"
            style={{
              display: 'flex',
              alignItems: 'left',
              flexDirection: 'column',
              color: '#fff',
            }}>

            <div className="columns">
              <div className="column is-4 main-text">
                <div>
                  <h1 className="has-text-weight-bold" style={{ lineHeight: '1.2', marginBottom: '10px' }}>{heading}</h1>
                  <p>{subheading}</p>
                  <div className="cta-btn cta-btn__primary">
                    <a href={`${ctaurl}`} className="btn-underline" target="_blank" rel="noopener noreferrer">{ctatitle}</a>
                  </div>
                  <p><a className="cta-txt cta-txt__secondary" href={`${ctasecondaryurl}`}>{ctasecondarytitle}</a></p>
                </div>
              </div>
              <div className="column is-8">
                <div className="laptop-image" onClick={() => setModalIsOpen(true)}>
                  <PreviewCompatibleImage imageInfo={image} />
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="section" style={{padding: '0'}}>
            <div className="columns">
              <div className="column is-12">
                <div className="content hm-page">

                  <div className="columns allens-interests-section">
                    <div className="column is-offset-1 is-10">
                      <div className="column is-12 two-column">
                        <section className="section columns">
                          <div className="has-text-centered column is-offset-1 is-7">
                            <div className="two-column__image-wrapper">
                              <PreviewCompatibleImage imageInfo={allensinterests.image} />
                            </div>
                          </div>
                          <div className="column is-4 two-column--text">
                            <div className="two-column--text-body-wrapper">
                              <h2>{allensinterests.header}</h2>
                              <div className="two-column--text-body"
                                style={{
                                  fontSize: '0.9em'
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: allensinterests.text,
                                  }}
                                />
                              </div>
                            </div>
                        </section>
                    </div>
                    </div>
                  </div>

                  <PreviewCompatibleBgImage
                    bgTag="div"
                    bgClass="hm-full-width"
                    imageInfo={bgimagesecondary}
                    style={{
                      backgroundPosition: `center center`,
                      justifyContent: 'left',
                      flexWrap: `wrap`,
                      backgroundRepeat: 'no-repeat',
                      height: 'auto',
                      paddingBottom: '60px'
                    }}
                  >
                    <div className="container">
                      <div className="columns customer-interest-section">
                        <div className="column is-offset-1 is-10">
                          <div className="column is-12 two-column">
                            <section className="section columns">
                              <div className="has-text-centered column is-offset-2 is-6">
                                <div className="two-column__image-wrapper">
                                  <PreviewCompatibleImage imageInfo={customerinterest.image} />
                                </div>
                              </div>
                              <div className="column is-4 two-column--text">
                                <div className="two-column--text-body-wrapper">
                                  <h2>{customerinterest.header}</h2>
                                  <div className="two-column--text-body"
                                    dangerouslySetInnerHTML={{
                                      __html: customerinterest.text,
                                      }}
                                    />
                                  </div>
                                </div>
                            </section>
                        </div>
                        </div>
                      </div>
                    </div>
                  </PreviewCompatibleBgImage>
                  <div className="columns two-column-section project-overview-three">
                    <div className="column is-offset-1 is-10">
                      <div className="column is-12 two-column">
                        <section className="section columns">
                          <div className="has-text-centered column is-offset-1 is-7">
                            <ButtonToggle gridItems={toggleimage}/>
                          </div>
                          <div className="column is-4 two-column--text">
                            <h2>{toggleimage.header}</h2>
                            <div className="two-column--text-body"
                              dangerouslySetInnerHTML={{
                                __html: toggleimage.text,
                              }}
                              />
                            <div className="cta-btn cta-btn__secondary">
                              <a href={`${ctaurl}`} className="btn-underline" target="_blank" rel="noopener noreferrer">{ctatitle}</a>
                            </div>
                          </div>
                        </section>
                    </div>
                    </div>
                  </div>
                  <div className="columns reasons-section">
                    <div className="column is-12">
                      <div className="reasons-section__icons">
                        <TwoColumn gridItems={reasons} />
                      </div>
                    </div>
                  </div>

                  <PreviewCompatibleBgImage
                      bgTag="div"
                      bgClass="hm-full-width"
                      imageInfo={bgimageprimary}
                      style={{
                        marginTop: '6em',
                        marginBottom: '6em',
                        backgroundPosition: `center center`,
                        justifyContent: 'left',
                        flexWrap: `wrap`,
                        backgroundRepeat: 'no-repeat',
                        height: 'auto',
                        paddingBottom: '60px'
                      }}
                    >
                    <div className="container">
                      <div className="columns personalize-emails">
                        <div className="column is-offset-1 is-11">
                          <div className="column is-12 two-column">
                            <section className="section columns">
                              <div className="has-text-centered column is-3 personalizeemails__left">
                                <div className="two-column__image-wrapper">
                                  <PreviewCompatibleImage imageInfo={personalizeemails.image1} />
                                </div>
                              </div>
                              <div className="column is-5 ">
                                <div className="personalizeemails__text">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: personalizeemails.header,
                                      }}
                                    />
                                  <div className="personalizeemails__text-main"
                                    dangerouslySetInnerHTML={{
                                      __html: personalizeemails.text,
                                      }}
                                    />

                                  <div className="personalizeemails__link">
                                    <Link className="btn-underline" to={`${personalizeemails.linkurl}`}>
                                    {personalizeemails.linktitle}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="column is-3 personalizeemails__right">
                                <div className="two-column__image-wrapper">
                                  <PreviewCompatibleImage imageInfo={personalizeemails.image2} />
                                </div>
                              </div>
                            </section>
                        </div>
                        </div>
                      </div>
                    </div>
                  </PreviewCompatibleBgImage>
                  <div className="columns seemless-integration-section">
                    <div className="column is-offset-1 is-10">
                      <div className="column is-12 two-column">
                        <section className="section columns">
                          <div className="has-text-centered column is-offset-1 is-7">
                            <div className="two-column__image-wrapper">
                              <SeemlessIntegrationSlider sliderItems={seemlessintegration.slides} />
                            </div>
                          </div>
                          <div className="column is-4 two-column--text">
                            <div className="two-column--text-body-wrapper">
                              <h2>{seemlessintegration.header}</h2>
                              <div className="two-column--text-body" style={{
                                  fontSize: '0.9em'
                              }}
                                dangerouslySetInnerHTML={{
                                  __html: seemlessintegration.text,
                                  }}
                                />
                                <div className="cta-btn cta-btn__secondary">
                                  <a href={`${ctaurl}`} className="btn-underline" target="_blank" rel="noopener noreferrer">{ctatitle}</a>
                                </div>
                              </div>
                            </div>
                        </section>
                    </div>
                    </div>
                  </div>
                  <div className="columns client-logos">
                    <div className="column is-offset-1 is-10">
                      <ClientLogos gridItems={clientlogos} />
                    </div>
                  </div>
                  <div className="columns final-quote-section">
                    <div className="column is-10 is-offset-1">
                      <div className="final-quote">
                        <h3 className="final-quote__header">{finalquote.header}</h3>
                        <p className="final-quote__subtitle">{finalquote.subtitle}</p>
                          <div className="final-quote__link">
                            <a href={`${finalquote.linkurl}`} className="btn-underline" target="_blank" rel="noopener noreferrer">{finalquote.linktitle}</a>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <VideoModal
      src={herovideo}
      open={modalIsOpen}
      closeModal={() => setModalIsOpen(false)}
    />
  </>
}

PersonalizePageTemplate.propTypes = {
  software: PropTypes.string,
  breadcrumb: PropTypes.string,
  title: PropTypes.string,
  metatitle: PropTypes.string,
  metadescription: PropTypes.string,
  bgimageprimary: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bgimagesecondary: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  ctatitle: PropTypes.string,
  ctaurl: PropTypes.string,
  ctasecondarytitle: PropTypes.string,
  ctasecondaryurl: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  herovideo: PropTypes.string,
  allensinterests: PropTypes.shape({
    header: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.string,
  }),
  customerinterest: PropTypes.shape({
    header: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.string,
  }),
  seemlessintegration: PropTypes.shape({
    header: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.string,
  }),
  toggleimage: PropTypes.shape({
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    header: PropTypes.string,
    text: PropTypes.string,
  }),
  reasons: PropTypes.array,
  personalizeemails: PropTypes.shape({
    header: PropTypes.string,
    text: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    linktitle: PropTypes.string,
    linkurl: PropTypes.string,
  }),
  finalquote: PropTypes.shape({
    header: PropTypes.string,
    subtitle: PropTypes.string,
    linktitle: PropTypes.string,
    linkurl: PropTypes.string,
  }),
  helmet: PropTypes.object,
}

const PersonalizePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <div className="subnavtop">
      <SubNavbar
        software={frontmatter.software}
      />
      <Layout>

        <PersonalizePageTemplate
          {...frontmatter}
          helmet={
            <Helmet titleTemplate="%s | Human">
              <title>{`${frontmatter.metatitle}`}</title>
              <meta name="description" content={`${frontmatter.metadescription}`} />
            </Helmet>
          }
        />
      </Layout>
    </div>
  )
}

PersonalizePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PersonalizePage

export const pageQuery = graphql`
  query PersonalizePageTemplate {
    markdownRemark( frontmatter: { templateKey: { eq: "personalize-page" } }) {
      frontmatter {
        software
        breadcrumb
        title
        metatitle
        metadescription
        bgimageprimary {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bgimagesecondary {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heading
        subheading
        ctatitle
        ctaurl
        ctasecondarytitle
        ctasecondaryurl
        image {
          childImageSharp {
            fluid(maxWidth: 900, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        herovideo
        allensinterests {
          header
          image {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text
        }
        customerinterest {
          header
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text
        }
        seemlessintegration {
          header
          image1 {
            childImageSharp {
              fluid(maxWidth: 700, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 700, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image3 {
            childImageSharp {
              fluid(maxWidth: 700, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          slides {
              image {
                childImageSharp {
                  fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              key
          }
          text
        }
        toggleimage {
          image1 {
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }
          image2 {
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }
          header
          text
        }
        reasons {
          image {
            childImageSharp {
              fluid(maxWidth: 170, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text
        }
        personalizeemails {
          header
          text
          linktitle
          linkurl
          image1 {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        clientlogos {
          image {
            childImageSharp {
              fluid(maxWidth: 180, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        finalquote {
          header
          subtitle
          linktitle
          linkurl
        }
      }
    }
  }
`
